
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthguardGuard } from './services/authguard.guard';
import { AuthServiceService } from './services/auth/auth-service.service';

const routes: Routes = [
  {
    canActivate: [AuthguardGuard],

    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    canActivate: [AuthServiceService],
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    canActivate: [AuthServiceService],

    path: 'login-popup',
    loadChildren: () => import('./login-popup/login-popup.module').then(m => m.LoginPopupPageModule)
  },

  {
    canActivate: [AuthguardGuard],
    path: 'product-details',
    loadChildren: () => import('./product-detail/product-detail.module').then(m => m.ProductDetailPageModule)
  },
  {
    canActivate: [AuthguardGuard],

    path: 'product-listing',
    loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingPageModule)
  },
  {
    canActivate: [AuthguardGuard],

    path: 'home-page',
    loadChildren: () => import('./home-page/home-page.module').then(m => m.HomePagePageModule)
  },
  {

    canActivate: [AuthServiceService],

    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then(m => m.SignupPageModule)
  },
  {
    path: 'forget-password',
    loadChildren: () => import('./forget-password/forget-password.module').then(m => m.ForgetPasswordPageModule)
  },
  {
    canActivate: [AuthguardGuard],

    path: 'detail/:id',
    loadChildren: () => import('./detail/detail.module').then(m => m.DetailPageModule)
  },
  {
    canActivate: [AuthguardGuard],

    path: 'product-detail',
    loadChildren: () => import('./food-detail/food-detail.module').then(m => m.FoodDetailPageModule)
  },
  {
    canActivate: [AuthguardGuard],

    path: 'add-to-cart',
    loadChildren: () => import('./add-to-cart/add-to-cart.module').then(m => m.AddToCartPageModule)
  },
  {

    // canActivate:[AuthguardGuard],
    path: 'otp',
    loadChildren: () => import('./otp/otp.module').then(m => m.OtpPageModule)
  },
  {
    canActivate: [AuthguardGuard],
    path: 'order-detail',
    loadChildren: () => import('./order-detail/order-detail.module').then(m => m.OrderDetailPageModule)
  },

  {
    canActivate: [AuthguardGuard],

    path: 'address',
    loadChildren: () => import('./address/address.module').then(m => m.AddressPageModule)
  },

  {
    canActivate: [AuthguardGuard],

    path: 'empty-cart',
    loadChildren: () => import('./empty-cart/empty-cart.module').then(m => m.EmptyCartPageModule)
  },
  {
    canActivate: [AuthguardGuard],

    path: 'loyalty-points',
    loadChildren: () => import('./loyalty-points/loyalty-points.module').then(m => m.LoyaltyPointsPageModule)
  },
  {
    canActivate: [AuthguardGuard],

    path: 'payment-option',
    loadChildren: () => import('./payment-option/payment-option.module').then(m => m.PaymentOptionPageModule)
  },
  {
    canActivate: [AuthguardGuard],

    path: 'payment-option2',
    loadChildren: () => import('./payment-option2/payment-option2.module').then(m => m.PaymentOption2PageModule)
  },
  {
    canActivate: [AuthguardGuard],

    path: 'my-profile',
    loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfilePageModule)
  },
  {
    canActivate: [AuthguardGuard],

    path: 'my-order',
    loadChildren: () => import('./my-order/my-order.module').then(m => m.MyOrderPageModule)

  },
  {
    canActivate: [AuthguardGuard],

    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then(m => m.FAQPageModule)
  },
  {
    canActivate: [AuthguardGuard],

    path: 'about-us',
    loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsPageModule)
  },
  {
    canActivate: [AuthguardGuard],

    path: 'legal',
    loadChildren: () => import('./legal/legal.module').then(m => m.LegalPageModule)
  },
  {
    canActivate: [AuthguardGuard],

    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsPageModule)
  },
  {
    canActivate: [AuthguardGuard],

    path: 'productitems-detail/:id',
    loadChildren: () => import('./fooditems-detail/fooditems-detail.module').then(m => m.FooditemsDetailPageModule)
  },
  {
    canActivate: [AuthguardGuard],

    path: 'payment-method',
    loadChildren: () => import('./payment-method/payment-method.module').then(m => m.PaymentMethodPageModule)
  },
  {
    canActivate: [AuthguardGuard],

    path: 'open-order-delivering',
    loadChildren: () => import('./open-order-delivering/open-order-delivering.module').then(m => m.OpenOrderDeliveringPageModule)
  },
  {
    canActivate: [AuthguardGuard],

    path: 'successful-order',
    loadChildren: () => import('./successful-order/successful-order.module').then(m => m.SuccessfulOrderPageModule)
  },

  {
    canActivate: [AuthguardGuard],

    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then(m => m.ChangePasswordPageModule)
  },

  {
    canActivate: [AuthguardGuard],

    path: 'successful-order',
    loadChildren: () => import('./successful-order/successful-order.module').then(m => m.SuccessfulOrderPageModule)
  },
  {
    canActivate: [AuthguardGuard],

    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then(m => m.ChangePasswordPageModule)
  },
  {
    canActivate: [AuthguardGuard],

    path: 'edit-profile/:id',
    loadChildren: () => import('./edit-profile/edit-profile.module').then(m => m.EditProfilePageModule)

  },
  {

    path: 'splash-screen',
    loadChildren: () => import('./splash-screen/splash-screen.module').then(m => m.SplashScreenPageModule)
  },
  {
    canActivate: [AuthguardGuard],

    path: 'favourite-dishes',
    loadChildren: () => import('./favourite-dishes/favourite-dishes.module').then(m => m.FavouriteDishesPageModule)
  },
  {
    canActivate: [AuthguardGuard],

    path: 'view-order-detail/:id',
    loadChildren: () => import('./view-order-detail/view-order-detail.module').then(m => m.ViewOrderDetailPageModule)
  },
  {
    path: 'order-tracking/:id',
    loadChildren: () => import('./order-tracking/order-tracking.module').then( m => m.OrderTrackingPageModule)
  },






];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }





