import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app/core/constant';
@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {
  basepath: string = AppSettings.API_ENDPOINT;

  constructor(private http: HttpClient, private router: Router,
  ) {

  }
  canActivate(): any {

    if (localStorage.getItem("token")) {
      this.router.navigate(['/home-page']);
      return false;

    }
    else {
      return true;
    }
  }
  AddUser(data: any) {
    console.log(data, 'dat7667')

    // const { body} =  data;
    return this.http.post<any>(`${this.basepath}signup`, data);
  }
  Sendotp(data: any) {
    // const { body} =  data;
    return this.http.post<any>(`${this.basepath}verifyotp`, data);
  }
  login(data: any) {
    // const { body} =  data;
    return this.http.post<any>(`${this.basepath}sendotp`, data);
  }
  getById(id:any){
    return this.http.get<any>(`${this.basepath}user/${id}`);


  }
  addAddress(data:any,id:any){
    return this.http.patch<any>(`${this.basepath}user/${id}`,data);

  }
}
