export class AppSettings {

    public static API_ENDPOINT = 'https://shoptly.api.stageprojects.xyz/';
    // public static API_ENDPOINT = "http://localhost:4003/";


    public static LOGIN_ERROR = 'Either username/password invalid. Please try again.';
    public static LOGOUT_SUCCESS = 'Signed you out';

    public static SITE_NAME = '';

    public static GENERIC_ERROR = 'There is some error. Please try again.';

    public static MESSAGE = {

        ADD_TITLE: "Registerd Successfully",
        ADD: "Congratulations you have created an account.",
        EDIT: "User Info has been updated",
        DELETE: "User has been deleted",
        DELETE_CONFIRM: "Are you sure you want to delete this user?",
        SIGNUP_ERR: "Email address already exist. Please try another.",
        FORGET_PASSWORD: "Reset Password link has been sent at your email address.",
        RESET_PASSWORD: "Congratulations! Your password has been reset successfully.",
        CHANGE_PASSWORD: "Congratulations! Your password has been changed successfully.",
        SMS: {
            SENT_SUCCCESSMSG: "Message Has Sent Successfully",
            SENT_SUCCCESS: "OTP has been sent to your registered phone no.",
            SENT_ERROR: "OTP can not be send to your registered phone no. Please try again.",
            RESENT_SUCCCESS: "OTP has been resent to your registered phone no.",
            VERIFY_SUCCESS: "Phone number has been verified successfully.",
            VERIFY_ERROR: "Wrong OTP entered. Please try again",
            VERIFY_ALREADY: "This phone number already verified. Please try again.",
            LIMIT_REACHED: "OTP has been expired. Please try with resend the OTP."
        },
        LOGIN: {
            VERFY_ACCESS_CODE_ERR: "Access Code is invalid. Please try again."
        },
        User: {
            ADD_TITLE: "Registerd Successfully",
            ADD: "Congratulations you have created an account. A confirmation email has been sent. To activate the iMDR, simply click on the word Verify in the email you received. Be sure to check your Clutter and Junk Email folders.",
            EDIT: "User Info has been updated",
            DELETE: "User has been deleted",
            DELETE_CONFIRM: "Are you sure you want to delete this user?",
            SIGNUP_ERR: "Email address already exist. Please try again.",
            FORGET_PASSWORD: "Reset Password link has been sent at your email address.",
            RESET_PASSWORD: "Congratulations! Your password has been reset successfully.",
            CHANGE_PASSWORD: "Congratulations! Your password has been changed successfully.",

        }
    }

}
